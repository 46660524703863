@import url(https://fonts.googleapis.com/css2?family=Gluten:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Gluten:wght@400;500&family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Gemunu+Libre:wght@200;300;400&family=Rubik:wght@300;400;500&family=Stick+No+Bills:wght@200;300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap);
body {
  margin: 0;
  font-family: -apple-system,
   BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app{
background-color: #e84393;
background-image: linear-gradient(145deg, #e7c9d8 0%, #000000 74%);}
.navbar {
  height: 10vh;
  width: 90%;
  margin: auto;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.navlogocontainer {
  display: flex;
  width: 100px;
  font-family: "Gluten", cursive;
}

.navlogocontainer > h1 {
    color:black;
  font-size: clamp(1rem, 1.5vw, 1.5rem);
  cursor:pointer;
}

.navlistcontainer {
  min-width: 150px;

  display: flex;
  font-family: "Source Sans Pro", sans-serif;
}
.navlist > a {
  text-decoration: none;
  color: white;
}
.navlist > a:active {
  text-decoration: none;
  color: white;
}

.navlist {
  display: flex;
  list-style: none;
  margin: auto;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.navlistelements {
  margin: 20px 5px;
  padding: 10px 15px;
  font-size: clamp(1rem, 0.9vw, 1.5rem);
  font-weight: 800;
}
.navlistelements:hover {
  -webkit-transition: 400ms;
  transition: 400ms;
  background-color: white;
  color: black;
}


@media screen and (max-width:511px){
.navlistcontainer{
    display: none;
}


}
.account-details-section{
    min-height:90vh;
    height:auto;
    width:100%;
}


.account-details-grid-wrapper{
    width:100%;
    height:100%;
    display:grid;

    grid-template-areas:"card card transact transact"
                        "card card loans loans"
                        "card card history history"
                        "deposit deposit withdraw withdraw";
                            grid-template-columns: 2fr 1fr 1fr 2fr;
    grid-template-rows:1fr 1fr 1fr 1fr;
}



.inner-wrapper{
    margin:50px;
}

.account-card{
  font-family: 'Rubik', sans-serif;

  font-weight:600;
    margin:100px 100px;
    border-radius:10px;
    background-color: black;
    color:white;
    grid-area:card;
}





.add-balance-card{
     margin:20px 100px;
    grid-area:deposit
}


.withdraw-balance-card{
     margin:30px;
    grid-area:withdraw
}

.withdraw-balance-card>h1{
    color: white;

}
.transfer-balance-card{
    display: flex;
    flex-direction: column;
     margin:30px;
    grid-area:transact
}

.transfer-balance-card>h1{
    color:white
}

.loan-transaction-card>h1{
    color:white
}

.add-balance-card>h1{
    color:white
}

.loan-transaction-card{
     margin:30px;
    grid-area:loans
}



.form-input-account{
  outline:none;
  border:none;
  height:36px;
  width:250px;
  font-size: clamp(1.2rem,1.4rem,2rem);
}



.transact-from{
    display: flex;
    flex-direction:column;
}



.transaction-history{
    grid-area:history;
    margin:30px;

}

.transaction-history>h1{
    color:white;
}
.approve-button{
    margin-left:10px;
    background-color: black;
    height:40px;
    width:220px;
    color:white;
    border-radius: 10px;
    outline: none;
    border:none;
}

.approve-button:hover{
    cursor:pointer;
    -webkit-transition: 400ms;
    transition: 400ms;
    background-color: white;
    color:black;
    height:40px;

}





.create-account{
    height:90vh;
    display:flex;
    flex-direction: row;
}

.form-wrapper-container{
    height:100%;
    width:50%;
    
    /* display:flex;
    flex-direction:column; */
}
.form-wrapper{
    display: flex;
    flex-direction: column;
   
    /* align-items: center; */
    margin:0 30px;
    padding:100px ;

}

.form-wrapper>h1{
    font-weight: 800;
    font-size: clamp(1.2rem,3.5rem,6rem);
}

.form-wrapper>h2{
    font-weight: 600;
    font-size: clamp(0.8rem,1vw+1.5rem,4rem);
}

.create-form{

 display:flex;
 flex-direction: column;
}
.form-input-field{
    margin:20px 0;
    background-color: black;
    outline:none;
    border: none;
    height:50px;
    color:white;
   font-size:1.2rem;
   border-radius: 5px;
}


.submit-button{
    margin:20px 0;
    background-color: black;
    outline: none;
    border: none;
    border-radius: 10px;
    height: 40px;
    width:200px;
    color:white;


}

.submit-button:hover{
    background-color: white;
    color: black;
    -webkit-transition: 400ms;
    transition: 400ms;
    cursor: pointer;
}
.image-wrapper{
    height:100%;
    width:50%;
    display: flex;
    align-items: center;
    
}

.create-account-image{
    width:80%;
    height:auto;
}



.display-accounts{
    min-height:90vh;
    height:auto;
    display:flex;
    flex-direction:row;
}

.accounts{
height:100%;
width:55%;
display: flex;
flex-direction: column;

justify-content: center;
}



.accounts>h1{
    font-weight: 800;
    font-size:clamp(1.2rem,3.2rem,6rem);
    margin:40px 50px 10px 50px;
}
.account-bars-container{
    height:80%;
    width:90%;
    padding:30px;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.account-bars{
    margin:30px;
    padding:0 30px;
    height:100px;
    width:90%;
    background-color: black;
    
    border-radius: 15px;
    color:white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


.account-bars:hover{
    -webkit-transition: 400ms;
    transition: 400ms;
    background-color: white;
    color:black;
    cursor: pointer;
}

.account-bars-title{
    font-size:clamp(0.5rem,0,8rem,1rem);
    font-weight: 300;
}


.accounts-image{

    height:100%;
    width:45%;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.accounts-display-image{
    height:65%;
    width:65%;
}
.account-transaction-wrapper{
    display:flex;
    /* flex-wrap:wrap; */
    width:100%;
    flex-direction: row;
    height:auto;
    min-height:90vh;
}
.account-transactions{
    min-height:90vh;
    height:auto;
    width:100%;
 
}

.account-transactions>h1{

    font-weight:800;
    font-size:clamp(1.2rem,3.5rem,5rem);
    margin-left:50px

}
.account-transaction-bars{
  margin:20px;
  padding:10px;
  display:flex;
  width:100%;
  flex-direction: column;
}

.transaction-bar{

    margin:10px;
    padding:0 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: white;
    background-color: black;
    height:80px;
    width:90%;
    border-radius:10px;
    cursor:pointer;
}

.transaction-bar-header{

    padding:0 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height:80px;
    width:90%;
    border-radius:10px;
    cursor:pointer;
}
.col-1{
    text-align: center;
    padding:1px;
    width:8%;
}
.col-2{
      text-align: center;
     padding:1px;
    width:42%;
}

.col-3{
      text-align: center;
     padding:1px;
    width:14%;
}

.col-4{
      text-align: center;
     padding:1px;
    width:32%;
}

.display-transaction-image{
    display:flex;
    justify-content: center;
    width:50%;
}
.loan-section {
  height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.loan-text-wrapper {
  margin: 60px;
  width: 50%;
}

.loan-image-wrapper {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
}
.loan-text-wrapper > h1 {
  font-weight: 800;
  font-size: clamp(1rem, 3.2rem, 5rem);
}

.loan-text-wrapper > h2 {
  font-weight: 600;
  font-size: clamp(0.8rem, 1.5rem, 4rem);
}


.loan-form{
    display:flex;
    flex-direction: column;

}

.loan-form-input{
    background-color: black;
    border-radius: 10px;
    border:none;
    outline: none;
    height:50px;
    margin:15px 0;
    width:600px;
    cursor: pointer;
    color:white;
    font-size:1.2rem;

}

.loan-submit-button{
    margin: 15px 0;
    background-color: black;
    color:white;
    height:40px;
    width:160px;
    border-radius: 10px;
    outline: none;
    border:none;
    cursor: pointer;
}

.loan-submit-button:hover{
    background-color: white;
    color:black;
    -webkit-transition: 400ms;
    transition: 400ms;

}

.main-section{

width:100%;
height:90vh;
max-height: -webkit-fit-content;
max-height: -moz-fit-content;
max-height: fit-content;
margin:0;
padding:0;
}

.main-section-grid{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height:100%;
  
}
.hero-panel{

    width:50%;
    height:100%;
     margin:auto;

}
.bank-image-container{

    align-items: center;
    width:50%;
    height:100%;
     margin:auto;
     display: flex;
     align-items: center;
}

.bank-image{
    width:80%;
    height:60%;
  
}

.content-container{
 
    font-family: 'Poppins', sans-serif;
    margin:auto;
    width:80%;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
}
    
.content-container >h1{
    text-transform: uppercase;
    font-size: clamp(1.5rem,5vw,4rem);
    color:black
}
.content-container >h2{
    
    font-size: clamp(1.2rem,2vw,4rem);
    color:black;
}
.content-container >h3{
    color:black;
    font-size: clamp(0.7rem,0.8vw,4rem);
    font-weight: 500;
}


.start-button{
    outline: 0;
    border:0;
    background-color: black;
    color: white;
    height:40px;
    width:120px;
    -webkit-transition: 400ms;
    transition: 400ms;
}

.start-button:hover{
    -webkit-transition: 400ms;
    transition: 400ms;
    cursor: pointer;
    background-color: white;
    color:black;
}
.transaction-section {
    height:90vh;
    display:flex;
    flex-wrap:wrap;
}

.transfer-button{
    cursor:pointer;
}

.transfer-section-wrapper{
    margin:50px 100px;
    display:flex;
    flex-direction:column;
}

.transfer-section-wrapper>h1{
    font-weight:800;
    font-size:clamp(1.2rem,3.5rem,6rem);

}

.transfer-section-wrapper>h2{
    font-size: 500;
    font-size:(1rem,2rem,4rem);

}

.transaction-form{
    display:flex;
    flex-direction: column;

}

.transaction-input{
    margin:20px 0;
    width:800px;
    height:50px;
    background-color: black;
    color:white;
    outline:none;
    border:none;
    font-size:clamp(1rem,1.3rem,2rem);
    border-radius:10px ;

}

.transfer-button{
     color:black;
    background-color: white;
   
    border-radius:10px;
   
    outline:none;
    border:none;
    height:40px;
    width:200px;
}

.transfer-button:hover{
    -webkit-transition: 400ms;
    transition: 400ms;
     color:white;
    background-color: black;
    cursor:pointer;
}
