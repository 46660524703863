.account-transaction-wrapper{
    display:flex;
    /* flex-wrap:wrap; */
    width:100%;
    flex-direction: row;
    height:auto;
    min-height:90vh;
}
.account-transactions{
    min-height:90vh;
    height:auto;
    width:100%;
 
}

.account-transactions>h1{

    font-weight:800;
    font-size:clamp(1.2rem,3.5rem,5rem);
    margin-left:50px

}
.account-transaction-bars{
  margin:20px;
  padding:10px;
  display:flex;
  width:100%;
  flex-direction: column;
}

.transaction-bar{

    margin:10px;
    padding:0 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: white;
    background-color: black;
    height:80px;
    width:90%;
    border-radius:10px;
    cursor:pointer;
}

.transaction-bar-header{

    padding:0 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height:80px;
    width:90%;
    border-radius:10px;
    cursor:pointer;
}
.col-1{
    text-align: center;
    padding:1px;
    width:8%;
}
.col-2{
      text-align: center;
     padding:1px;
    width:42%;
}

.col-3{
      text-align: center;
     padding:1px;
    width:14%;
}

.col-4{
      text-align: center;
     padding:1px;
    width:32%;
}

.display-transaction-image{
    display:flex;
    justify-content: center;
    width:50%;
}