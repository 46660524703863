@import url("https://fonts.googleapis.com/css2?family=Gluten:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gluten:wght@400;500&family=Source+Sans+Pro&display=swap");
.navbar {
  height: 10vh;
  width: 90%;
  margin: auto;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.navlogocontainer {
  display: flex;
  width: 100px;
  font-family: "Gluten", cursive;
}

.navlogocontainer > h1 {
    color:black;
  font-size: clamp(1rem, 1.5vw, 1.5rem);
  cursor:pointer;
}

.navlistcontainer {
  min-width: 150px;

  display: flex;
  font-family: "Source Sans Pro", sans-serif;
}
.navlist > a {
  text-decoration: none;
  color: white;
}
.navlist > a:active {
  text-decoration: none;
  color: white;
}

.navlist {
  display: flex;
  list-style: none;
  margin: auto;
  width: max-content;
}

.navlistelements {
  margin: 20px 5px;
  padding: 10px 15px;
  font-size: clamp(1rem, 0.9vw, 1.5rem);
  font-weight: 800;
}
.navlistelements:hover {
  transition: 400ms;
  background-color: white;
  color: black;
}


@media screen and (max-width:511px){
.navlistcontainer{
    display: none;
}


}