.create-account{
    height:90vh;
    display:flex;
    flex-direction: row;
}

.form-wrapper-container{
    height:100%;
    width:50%;
    
    /* display:flex;
    flex-direction:column; */
}
.form-wrapper{
    display: flex;
    flex-direction: column;
   
    /* align-items: center; */
    margin:0 30px;
    padding:100px ;

}

.form-wrapper>h1{
    font-weight: 800;
    font-size: clamp(1.2rem,3.5rem,6rem);
}

.form-wrapper>h2{
    font-weight: 600;
    font-size: clamp(0.8rem,1vw+1.5rem,4rem);
}

.create-form{

 display:flex;
 flex-direction: column;
}
.form-input-field{
    margin:20px 0;
    background-color: black;
    outline:none;
    border: none;
    height:50px;
    color:white;
   font-size:1.2rem;
   border-radius: 5px;
}


.submit-button{
    margin:20px 0;
    background-color: black;
    outline: none;
    border: none;
    border-radius: 10px;
    height: 40px;
    width:200px;
    color:white;


}

.submit-button:hover{
    background-color: white;
    color: black;
    transition: 400ms;
    cursor: pointer;
}
.image-wrapper{
    height:100%;
    width:50%;
    display: flex;
    align-items: center;
    
}

.create-account-image{
    width:80%;
    height:auto;
}


