.transaction-section {
    height:90vh;
    display:flex;
    flex-wrap:wrap;
}

.transfer-button{
    cursor:pointer;
}

.transfer-section-wrapper{
    margin:50px 100px;
    display:flex;
    flex-direction:column;
}

.transfer-section-wrapper>h1{
    font-weight:800;
    font-size:clamp(1.2rem,3.5rem,6rem);

}

.transfer-section-wrapper>h2{
    font-size: 500;
    font-size:(1rem,2rem,4rem);

}

.transaction-form{
    display:flex;
    flex-direction: column;

}

.transaction-input{
    margin:20px 0;
    width:800px;
    height:50px;
    background-color: black;
    color:white;
    outline:none;
    border:none;
    font-size:clamp(1rem,1.3rem,2rem);
    border-radius:10px ;

}

.transfer-button{
     color:black;
    background-color: white;
   
    border-radius:10px;
   
    outline:none;
    border:none;
    height:40px;
    width:200px;
}

.transfer-button:hover{
    transition: 400ms;
     color:white;
    background-color: black;
    cursor:pointer;
}